<template>
  <form-view
    v-loading="loading"
    type="verify"
    abortable
    :abort-method="abortMethod"
    :verify-method="verifyMethod"
    success-route="production-monitor"
  >
    <div class="section-title-row">
      <div class="title">车辆基本信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label="自编号">
          <el-input disabled v-model="dataForm.number"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="车牌号码">
          <el-input disabled v-model="dataForm.licensePlate"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="车长">
          <el-input disabled v-model="dataForm.nickName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="车长电话">
          <el-input disabled v-model="dataForm.phone"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label="提交时间">
          <el-input disabled v-model="dataForm.createTime"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="工地地址">
          <el-input disabled v-model="dataForm.location"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-tag type="danger" v-if="dataForm.hazardSource">有危险源</el-tag>
        <el-tag type="danger" v-if="dataForm.singleSide">单边支撑</el-tag>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">实时视频</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="20">
      <el-col :span="6" class="video-item" v-for="(v, i) in videoList" :key="i">
        <flv-player :switch-url="v.switchUrl" :video-url="v.videoUrl"></flv-player>
        <div class="video-title">
          <div class="title-bg"></div>
          <span>{{videoType[i]}}</span>
        </div>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">上传资料</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="20">
      <el-col :span="6" v-for="(image, i) in imageList" :key="i">
        <div class="upload-wrapper">
          <image-uploader disabled :limit="1" :images="image.list" no-title></image-uploader>
          <div class="title">{{image.title}}</div>
        </div>
      </el-col>
    </el-row>
  </form-view>
</template>

<script>
import { videoType, staticUrl, restfulUrl, webSocketUrl } from '@/config'
import FlvPlayer from '@/components/flv-player.vue'
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'

export default {
  name: 'production-approve',

  components: { FormView, ImageUploader, FlvPlayer },

  data () {
    return {
      loading: false,
      dataForm: {},
      imageList: [],
      videoList: [],
      videoType
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.$route.query.id
      if (id) {
        this.loading = true
        const data = await this.$http({
          url: this.$http.adornUrl('/production/carPermission/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          const list = []
          list.push({ title: '左前支撑腿照片', list: [{ url: data.datas.imageBracketBeforeLeft ? staticUrl + data.datas.imageBracketBeforeLeft : '' }] })
          list.push({ title: '左后支撑腿照片', list: [{ url: data.datas.imageBracketAfterLeft ? staticUrl + data.datas.imageBracketAfterLeft : '' }] })
          list.push({ title: '右前支撑腿照片', list: [{ url: data.datas.imageBracketBeforeRight ? staticUrl + data.datas.imageBracketBeforeRight : '' }] })
          list.push({ title: '右后支撑腿照片', list: [{ url: data.datas.imageBracketAfterRight ? staticUrl + data.datas.imageBracketAfterRight : '' }] })
          list.push({ title: '整车照片', list: [{ url: data.datas.imageWholeCar ? staticUrl + data.datas.imageWholeCar : '' }] })
          list.push({ title: '就位确认单照片', list: [{ url: data.datas.imageConfirm ? staticUrl + data.datas.imageConfirm : '' }] })
          list.push({ title: '单边支撑照片', list: [{ url: data.datas.imageSingleSide ? staticUrl + data.datas.imageSingleSide : '' }] })
          list.push({ title: '危险源施工方案照片', list: [{ url: data.datas.imageHazardSource ? staticUrl + data.datas.imageHazardSource : '' }] })
          this.imageList = list
          const vList = []
          const carId = data.datas.carDeviceId
          for (let i = 1; i < 9; i++) {
            const param = {
              tdh: i,
              type: 1,
              tid: carId
            }
            vList.push({
              switchUrl: restfulUrl + `/api.json?func=1003&carid=${carId}&tdh=${i}`,
              videoUrl: webSocketUrl + `/websocket?token=&params=${encodeURIComponent(JSON.stringify(param))}`
            })
          }
          this.videoList = vList
          this.loading = false
        }
      }
    },

    abortMethod (value) {
      return this.$http({
        url: this.$http.adornUrl('/production/carPermission/review'),
        method: 'post',
        data: {
          id: this.$route.query.id,
          status: 3,
          remarks: value
        }
      })
    },

    verifyMethod () {
      return this.$http({
        url: this.$http.adornUrl('/production/carPermission/review'),
        method: 'post',
        data: {
          id: this.$route.query.id,
          status: 2
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-tag {
  margin: 4px;
}
.video-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  height: 300px;
  .video-title {
    position: relative;
    z-index: 1;
    width: 100%;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    color: #fff;
    text-align: center;
  }
  .title-bg {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.8;
    border-radius: 0px 0px 4px 4px;
  }
  .flv-player-wrapper {
    height: calc(100% - 32px);
  }
}
</style>
